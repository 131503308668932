:root {
  --cap-input-height: 30px;

  // Default dynamic filter settings,
  // can be changed using themeService.setProps()
  --cap-dynamic-filter-input-height: 30px;
  --cap-dynamic-filter-border-radius: 5px;
  --cap-dynamic-filter-border-width: 1px;
  --cap-dynamic-filters-hide-filters-on-mobile: false;
  --cap-dynamic-filters-filter-flex: 1;
  --cap-dynamic-filters-flex-wrap: wrap;
  --cap-dynamic-filter-width: 200px;
  --cap-dynamic-filter-label-font-size: 14px;
  --cap-dynamic-filter-label-font-weight: 600;
  --cap-dynamic-filter-label-font-family: 'Source Sans Pro';
  --cap-dynamic-filter-label-font-color: #5f6062;
  --cap-dynamic-filter-placeholder-font-weight: 600;
  --cap-dynamic-filter-placeholder-font-color: #5f6062;
  --cap-dynamic-filter-icon-opacity: 1;
  --cap-dynamic-filter-icon-color: var(--cap-primary-color);
}

$input-border-color: #ddd !default;
$cap-input-border-color: #ddd !default;
$cap-input-height: var(--cap-input-height) !default;
$cap-light-gray: #f0f0f0 !default;
$cap-dark-gray: #333 !default;
$cap-accordion-icon-color: #848484 !default;
$cap-white: #fff !default;
$cap-black: #000 !default;
$cap-gray: #ddd !default;
$cap-success: #68e76f !default;
$cap-warning: #faa200 !default;
$cap-danger: #fa4331 !default;
$cap-platinum: #f9f9f9 !default;
$cap-primary-font: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif !default; /* stylelint-disable-line value-keyword-case */

// Toast
$cap-toast-background-color: #fff !default;
$cap-toast-text-color: #333 !default;
$cap-toast-background-color-success: $cap-toast-background-color !default;
$cap-toast-background-color-warning: $cap-toast-background-color !default;
$cap-toast-background-color-info: $cap-toast-background-color !default;
$cap-toast-background-color-error: $cap-toast-background-color !default;
$cap-toast-summary-font-size: 18px !default;
$cap-toast-summary-font-weight: 600 !default;
$cap-toast-summary-font-family: $cap-primary-font !default;
$cap-toast-summary-color: $cap-toast-text-color !default;

$cap-toast-detail-font-size: 14px !default;
$cap-toast-detail-text-color: #4c4c4c !default;

$cap-toast-close-icon-color: rgba(51, 51, 51, 0.15) !default;
$cap-toast-close-icon-top: 0 !default;
$cap-toast-close-icon-bottom: 0 !default;
$cap-toast-close-icon-right: 15px !default;
$cap-toast-close-icon-justify-content: center !default;
$cap-toast-close-icon-display: flex !default;
$cap-toast-close-icon-flex-direction: column !default;
$cap-toast-close-icon-flex-font-size: 20px !default;

$cap-toast-icon-font-size: 29px !default;
$cap-toast-icon-margin-right: 30px !default;
$cap-toast-border-radius: 5px !default;
$cap-toast-message-content-padding: 15px 20px !default;

$cap-toast-message-error-background: $cap-toast-background-color-error !default;
$cap-toast-message-error-border-left: 4px solid $cap-danger !default;

$cap-toast-message-info-background: $cap-toast-background-color-error !default;
$cap-toast-message-info-border-left: 4px solid var(--cap-primary-color) !default;

// Filters
$cap-dynamic-filter-border-radius: var(--cap-dynamic-filter-border-radius) !default;
$cap-dynamic-filter-border-width: var(--cap-dynamic-filter-border-width) !default;
$cap-dynamic-filters-hide-filters-on-mobile: var(--cap-dynamic-filters-hide-filters-on-mobile) !default;
$cap-dynamic-filters-filter-flex: var(--cap-dynamic-filters-filter-flex) !default;
$cap-dynamic-filters-flex-wrap: var(--cap-dynamic-filters-flex-wrap) !default;
$cap-dynamic-filter-height: var(--cap-dynamic-filter-input-height) !default;
$cap-dynamic-filter-width: var(--cap-dynamic-filter-width) !default;
$cap-dynamic-filter-label-font-size: var(--cap-dynamic-filter-label-font-size) !default;
$cap-dynamic-filter-label-font-weight: var(--cap-dynamic-filter-label-font-weight) !default;
$cap-dynamic-filter-label-font-family: var(--cap-dynamic-filter-label-font-family) !default;
$cap-dynamic-filter-label-font-color: var(--cap-dynamic-filter-label-font-color) !default;
$cap-dynamic-filter-label-padding-left: 35px;

$cap-dynamic-filter-icon-opacity: var(--cap-dynamic-filter-icon-opacity) !default;
$cap-dynamic-filter-icon-color: var(--cap-dynamic-filter-icon-color) !default;

$cap-dynamic-filter-placeholder-font-color: var(--cap-dynamic-filter-placeholder-font-color) !default;
$cap-dynamic-filter-placeholder-font-weight: var(--cap-dynamic-filter-placeholder-font-weight) !default;

// Action Menu
$cap-action-menu-disabled-text-color: #776f6f;

// Fab Button
$cap-fab-button-size: 56px !default;
$cap-fab-button-margin-size: 16px !default;
$cap-fab-button-icon-size: 22px !default;

$cap-fab-button-width: $cap-fab-button-size !default;
$cap-fab-button-height: $cap-fab-button-size !default;
$cap-fab-button-background-color: var(--cap-primary-color) !default;
$cap-fab-button-border-radius: 50% !default;
$cap-fab-button-display: flex !default;
$cap-fab-button-justify-content: center !default;
$cap-fab-button-align-items: center !default;
$cap-fab-button-border: none !default;
$cap-fab-button-position: fixed !default;
$cap-fab-button-bottom: $cap-fab-button-margin-size !default;
$cap-fab-button-z-index: 10 !default;

$cap-fab-button-icon-width: $cap-fab-button-icon-size !default;
$cap-fab-button-icon-height: $cap-fab-button-icon-size !default;
$cap-fab-button-icon-font-size: $cap-fab-button-icon-size !default;
$cap-fab-button-icon-color: $cap-white !default;
$cap-fab-button-text-align: center !default;

$cap-fab-button-top-right-right: $cap-fab-button-margin-size !default;
$cap-fab-button-top-right-top: $cap-fab-button-margin-size !default;

$cap-fab-button-bottom-left-left: $cap-fab-button-margin-size !default;
$cap-fab-button-bottom-left-bottom: $cap-fab-button-margin-size !default;

$cap-fab-button-bottom-top-left-left: $cap-fab-button-margin-size !default;
$cap-fab-button-bottom-top-left-top: $cap-fab-button-margin-size !default;

$cap-fab-button-bottom-right-right: $cap-fab-button-margin-size !default;
$cap-fab-button-bottom-right-bottom: $cap-fab-button-margin-size !default;

// File Upload
$cap-file-upload-padding: 15px !default;
$cap-file-upload-width: 100% !default;
$cap-file-upload-height: 140px !default;
$cap-file-upload-background-color: #fff !default;
$cap-file-upload-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !default;
$cap-file-upload-display: flex !default;
$cap-file-upload-flex-direction: column !default;
$cap-file-upload-justify-content: center !default;
$cap-file-upload-color: var(--cap-primary-color) !default;
$cap-file-upload-font-weight: 400 !default;

$cap-file-upload-dragover-border: 1px dashed var(--cap-primary-color) !default;
$cap-file-upload-icon-opacity: 0.5 !default;
$cap-file-upload-icon-font-size: 32px !default;
$cap-file-upload-icon-display: block !default;
$cap-file-upload-icon-margin-bottom: 8px !default;

// Form field
$cap-form-field-label-font-weight: 600 !default;

// Desktop Stepper
$cap-desktop-stepper-icon-size: 24px !default;
$cap-desktop-stepper-horizontal-background-color: transparent !default;
$cap-desktop-stepper-horizontal-header-container-white-space: nowrap !default;
$cap-desktop-stepper-horizontal-header-container-align-items: center !default;
$cap-desktop-stepper-horizontal-header-container-justify-content: space-between !default;
$cap-desktop-stepper-horizontal-header-height: 30px !default;
$cap-desktop-stepper-horizontal-align-items: center !default;

$cap-desktop-stepper-icon-content-align-self: center !default;
$cap-desktop-stepper-icon-content-line-height: 1.5 !default;
$cap-desktop-stepper-icon-border-radius: 50% !default;
$cap-desktop-stepper-icon-border: 1px solid var(--cap-primary-color) !default;
$cap-desktop-stepper-icon-height: $cap-desktop-stepper-icon-size !default;
$cap-desktop-stepper-icon-width: $cap-desktop-stepper-icon-size !default;
$cap-desktop-stepper-step-icon-background-color: $cap-white !default;
$cap-desktop-stepper-step-icon-color: var(--cap-primary-color) !default;
$cap-desktop-stepper-step-icon-justify-content: center !default;
$cap-desktop-stepper-step-icon-completed-background-color: var(--cap-primary-color) !default;
$cap-desktop-stepper-step-icon-color: white !default;

$cap-desktop-stepper-horizontal-line-border-top-style: solid !default;
$cap-desktop-stepper-horizontal-line-min-width: 32px !default;
$cap-desktop-stepper-horizontal-line-opacity: 0.3 !default;
$cap-desktop-stepper-horizontal-line-border-top: 5px solid var(--cap-primary-color) !default;
$cap-desktop-stepper-horizontal-line-color: var(--cap-primary-color) !default;

$cap-desktop-stepper-step-label-font-size: 10px !default;
$cap-desktop-stepper-step-label-active-color: var(--cap-primary-color) !default;
$cap-desktop-stepper-step-label-inactive-color: var(--text-color-2) !default;

// Dropdown
$cap-dropdown-background: $cap-white !default;
$cap-dropdown-border: 1px solid $input-border-color !default;
$cap-dropdown-transition: border-color 0.2s !default;
$cap-dropdown-height: $cap-input-height !default;
$cap-dropdown-label-container-height: $cap-input-height !default;

$cap-dropdown-multiselect-label-padding: .95rem .7rem !default;
$cap-dropdown-multiselect-label-padding-right: 2em !default;
$cap-dropdown-multiselect-label-height: calc(#{$cap-input-height} - 2px) !default;
$cap-dropdown-multiselect-justify-content: center !default;
$cap-dropdown-multiselect-flex-direction: column !default;

$cap-dropdown-multiselect-selected-img-width: 16px !default;
$cap-dropdown-multiselect-selected-line-height: 16px !default;
$cap-dropdown-multiselect-selected-icon-font-size: 16px !default;
$cap-dropdown-multiselect-selected-img-vertical-align: middle !default;
$cap-dropdown-multiselect-selected-label-vertical-align: middle !default;
$cap-dropdown-multiselect-selected-label-margin-left: 0.5em !default;

$cap-dropdown-multiselect-trigger-background-color: $cap-white !default;
$cap-dropdown-multiselect-trigger-width: 2rem !default;
$cap-dropdown-multiselect-trigger-line-height: 2rem !default;
$cap-dropdown-multiselect-trigger-text-align: center !default;
$cap-dropdown-multiselect-trigger-color: var(--primary-text) !default;
$cap-dropdown-multiselect-trigger-border-left: 1px solid transparent !default;

$cap-dropdown-multiselect-clear-icon: var(--primary-text) !default;
$cap-dropdown-multiselect-cap-dropdown-label-padding-right: 4em !default;
$cap-dropdown-multiselect-input-padding: 8px 12px 8px 40px !default;
$cap-dropdown-multiselect-icon-color: var(--cap-primary-color) !default;
$cap-dropdown-multiselect-icon-margin-right: 5px !default;

$cap-dropdown-multiselect-checkbox-border: 1px solid $input-border-color !default;
$cap-dropdown-multiselect-checkbox-background-color: $cap-white !default;
$cap-dropdown-multiselect-checkbox-width: 20px !default;
$cap-dropdown-multiselect-checkbox-height: 20px !default;
$cap-dropdown-multiselect-checkbox-text-align: center !default;
$cap-dropdown-multiselect-checkbox-border-radius: 3px !default;
$cap-dropdown-multiselect-checkbox-border-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s !default;

$cap-dropdown-multiselect-panel-padding: 0 !default;
$cap-dropdown-multiselect-panel-border: 1px solid $input-border-color !default;
$cap-dropdown-multiselect-panel-background-color: $cap-white !default;
$cap-dropdown-multiselect-panel-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !default;
$cap-dropdown-multiselect-panel-items-padding: 0 !default;

$cap-dropdown-multiselect-panel-item-group-margin: 0 !default;
$cap-dropdown-multiselect-panel-item-group-padding: 8px 12px !default;
$cap-dropdown-multiselect-panel-item-group-border: 0 none !default;
$cap-dropdown-multiselect-panel-item-group-color: var(--primary-text) !default;
$cap-dropdown-multiselect-panel-item-group-background-color: transparent !default;
$cap-dropdown-multiselect-panel-item-group-border-radius: 0 !default;
$cap-dropdown-multiselect-panel-item-group-border-top: 1px solid rgba(0, 0, 0, 0.15) !default;
$cap-dropdown-multiselect-panel-item-highlight-color: $cap-white !default;
$cap-dropdown-multiselect-panel-item-highlight-background-color: var(--cap-primary-color) !default;

$cap-dropdown-multiselect-panel-custom-item-height: 18px !default;
$cap-dropdown-multiselect-panel-custom-img-width: 24px !default;
$cap-dropdown-multiselect-panel-custom-img-top: 50% !default;
$cap-dropdown-multiselect-panel-custom-img-left: 0 !default;
$cap-dropdown-multiselect-panel-custom-img-transform: translateY(-50%) !default;

$cap-dropdown-multiselect-panel-custom-label-font-size: 14px !default;
$cap-dropdown-multiselect-panel-custom-label-top: 50% !default;
$cap-dropdown-multiselect-panel-custom-label-left: 30px !default;
$cap-dropdown-multiselect-panel-custom-label-position: absolute !default;
$cap-dropdown-multiselect-panel-custom-label-transform: translateY(-50%) !default;

$cap-dropdown-multiselect-panel-filter-container-border-bottom: 1px solid #eaeaea !default;
$cap-dropdown-multiselect-panel-filter-container-color: var(--primary-text) !default;
$cap-dropdown-multiselect-panel-filter-container-background-color: $cap-white !default;
$cap-dropdown-multiselect-panel-filter-container-margin: 0 !default;
$cap-dropdown-multiselect-panel-filter-container-padding: 0 !default;

$cap-dropdown-multiselect-panel-filter-container-input-padding: 8px 12px 8px 40px !default;
$cap-dropdown-multiselect-panel-filter-container-input-width: 100% !default;
$cap-dropdown-multiselect-panel-filter-container-input-border: none !default;

$cap-dropdown-multiselect-panel-filter-container-icon-position: absolute !default;
$cap-dropdown-multiselect-panel-filter-container-icon-left: 12px !default;
$cap-dropdown-multiselect-panel-filter-container-icon-top: 50% !default;
$cap-dropdown-multiselect-panel-filter-container-icon-color: var(--cap-primary-color) !default;

// Floating labels
$floating-input-border: 1px solid $input-border-color !default;
$floating-placeholder-text-color: #5f6062 !default;

// Editor
$cap-editor-tox-border-radius: 3px !default;
$cap-editor-tox-border-color: $cap-input-border-color !default;
$cap-editor-tox-edit-area-border-top: none !default;
$cap-editor-tox-toolbar-padding: 10px 0 !default;
$cap-editor-tox-toolbar-border: none !default;
$cap-editor-tox-toolbar-background: #ddd !default;
$cap-editor-tox-toolbar-color: rgb(136 136 136) !default;

$cap-editor-tox-toolbar-btn-margin: 0 5px !default;
$cap-editor-tox-toolbar-btn-hover-background: darken(#ddd, 5%) !default;
$cap-editor-tox-toolbar-btn-select-background: #fff !default;

$cap-editor-svg-fill: rgba(51, 51, 51, 0.5) !default;

$cap-editor-min-height: 100px;
$cap-editor-max-height: 150px;

// PDF Viewer
$cap-pdf-viewer-button-size: 30px !default;
$cap-pdf-viewer-toolbar-icon-right: 15px !default;
$cap-pdf-viewer-toolbar-padding: 10px !default;

$cap-pdf-viewer-background-color: $cap-white !default;
$cap-pdf-viewer-width: 100% !default;
$cap-pdf-viewer-max-height: 100% !default;
$cap-pdf-viewer-display: block !default;
$cap-pdf-viewer-position: relative !default;

$cap-pdf-viewer-info-row-margin: 0 auto !default;
$cap-pdf-viewer-info-row-width: 100% !default;
$cap-pdf-viewer-info-row-text-align: center !default;
$cap-pdf-viewer-info-row-display: flex !default;
$cap-pdf-viewer-info-row-flex-direction: row !default;
$cap-pdf-viewer-info-row-flex-justify-content: center !default;

$cap-pdf-viewer-page-controls-hover-visibility: visible !default;
$cap-pdf-viewer-page-controls-display: inline-flex !default;
$cap-pdf-viewer-page-controls-visibility: hidden !default;
$cap-pdf-viewer-page-controls-width: 100% !default;
$cap-pdf-viewer-page-controls-justify-content: space-between !default;

$cap-pdf-viewer-page-controls-opacity: 0.4 !default;
$cap-pdf-viewer-page-controls-position: absolute !default;
$cap-pdf-viewer-page-controls-nav-width: 75px !default;
$cap-pdf-viewer-page-controls-nav-height: 200px !default;
$cap-pdf-viewer-page-controls-nav-background-color: var(--cap-primary-color) !default;
$cap-pdf-viewer-page-controls-nav-transition: opacity 0.25s linear !default;
$cap-pdf-viewer-page-controls-nav-icon-position: absolute !default;
$cap-pdf-viewer-page-controls-nav-icon-top: 50% !default;
$cap-pdf-viewer-page-controls-nav-icon-transform: translateY(-50%) !default;
$cap-pdf-viewer-page-controls-nav-icon-color: $cap-white !default;
$cap-pdf-viewer-page-controls-top: calc(50% - 0.5 * #{$cap-pdf-viewer-page-controls-nav-height}) !default;

$cap-pdf-viewer-toolbar-margin: 0 !default;
$cap-pdf-viewer-toolbar-info-column-float: left !default;
$cap-pdf-viewer-toolbar-info-column-padding: 10px !default;
$cap-pdf-viewer-toolbar-info-column-height: 100% !default;
$cap-pdf-viewer-toolbar-info-column-justify-content: center !default;
$cap-pdf-viewer-toolbar-info-column-display: flex !default;
$cap-pdf-viewer-toolbar-info-column-flex-direction: column !default;
$cap-pdf-viewer-toolbar-info-column-border-radius: 3px !default;

// Progress Bar
$cap-progress-bar-border: 0 none !default;
$cap-progress-bar-height: 24px !default;
$cap-progress-bar-background-color: #eaeaea !default;

$cap-progress-bar-progressbar-value-height: 100% !default;
$cap-progress-bar-progressbar-value-position: absolute !default;
$cap-progress-bar-progressbar-value-border: 0 none !default;
$cap-progress-bar-progressbar-value-margin: 0 !default;
$cap-progress-bar-progressbar-value-background: var(--cap-primary-color) !default;

$cap-progress-bar-label-height: 100% !default;
$cap-progress-bar-label-width: 100% !default;
$cap-progress-bar-label-position: absolute !default;
$cap-progress-bar-label-margin-top: 0 !default;
$cap-progress-bar-label-text-align: center !default;
$cap-progress-bar-label-font-weight: bold !default;
$cap-progress-bar-label-color: var(--primary-text) !default;
$cap-progress-bar-label-line-height: 24px !default;

// Progress Circle
$cap-progress-circle-position: relative !default;
$cap-progress-circle-display: inline-block !default;
$cap-progress-circle-custom: center !default;
$cap-progress-circle-position: absolute !default;
$cap-progress-circle-content-top: 0 !default;
$cap-progress-circle-subtitle-top: 50% !default;

// Loader
$cap-loader-image-width: fit-content !default;
$cap-loader-image-margin: 0 auto !default;

$cap-loader-position: fixed !default;
$cap-loader-top: 0 !default;
$cap-loader-left: 0 !default;
$cap-loader-bottom: 0 !default;
$cap-loader-width: 100% !default;
$cap-loader-background: rgba(0, 0, 0, 0.65) !default;
$cap-loader-z-index: 99 !default;
$cap-loader-text-align: center !default;
$cap-loader-display: flex !default;
$cap-loader-flex-direction: column !default;
$cap-loader-justify-content: center !default;
$cap-loader-text-color: var(--cap-primary-color) !default;
$cap-loader-progress-spinner-color-stroke: var(--cap-primary-color) !default;
$cap-loader-text-animation: dots 1s steps(5, end) infinite !default;

// Mobile Tab Bar
$cap-mobile-tab-bar-margin: 0.3rem !default;
$cap-mobile-tab-bar-display: flex !default;
$cap-mobile-tab-bar-justify-content: space-evenly !default;
$cap-mobile-tab-bar-margin-top: $cap-mobile-tab-bar-margin !default;
$cap-mobile-tab-bar-box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.3) !default;
$cap-mobile-tab-bar-padding: 10px 0 !default;
$cap-mobile-tab-bar-background-color: #fff !default;
$cap-mobile-tab-bar-shape-font-size: 2rem !default;
$cap-mobile-tab-bar-menu-item-margin-bottom: $cap-mobile-tab-bar-margin !default;
$cap-mobile-tab-bar-menu-item-font-size: 0.8rem !default;
$cap-mobile-tab-bar-menu-item-weight: 600 !default;

$cap-mobile-tab-bar-active-text-color: var(--cap-primary-color) !default;
$cap-mobile-tab-bar-rectangle-text-align: center !default;
$cap-mobile-tab-bar-word-break: break-all !default;
$cap-mobile-tab-bar-max-width: 20% !default;

// Notification Bar
$cap-notification-bar-square-size: 2rem !default;
$cap-notification-bar-circle-size: 1.2rem !default;
$cap-notification-bar-icon-size: 50px !default;
$cap-notification-bar-box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.2) !default;

$cap-notification-bar-background-color: #fbfbfb !default;
$cap-notification-bar-height: 100% !default;
$cap-notification-bar-padding: 0 1.5rem !default;

$cap-notification-bar-head-display: flex !default;
$cap-notification-bar-head-justify-content: space-between !default;
$cap-notification-bar-head-align-items: center !default;
$cap-notification-bar-head-margin: 2.5rem 0 2rem 0 !default;

$cap-notification-bar-icon-angle-left-color: var(--text-color-2) !default;
$cap-notification-bar-icon-angle-font-size: 2rem !default;
$cap-notification-bar-times-color: var(--cap-primary-color) !default;
$cap-notification-bar-times-text-align: center !default;
$cap-notification-bar-times-font-size: 1.3rem !default;

$cap-notification-bar-bell-width: $cap-notification-bar-square-size !default;
$cap-notification-bar-bell-height: $cap-notification-bar-square-size !default;
$cap-notification-bar-bell-border-radius: 3px !default;
$cap-notification-bar-bell-box-shadow: 0 1px 1px 0 dimgrey !default;
$cap-notification-bar-bell-background-color: var(--cap-primary-color) !default;
$cap-notification-bar-bell-center: center !default;
$cap-notification-bar-bell-position: relative !default;
$cap-notification-bar-bell-color: white !default;
$cap-notification-bar-bell-padding-top: 25% !default;

$cap-notification-bottom-bar-z-index: 1 !default;
$cap-notification-bottom-bar-box-shadow: 0 -12px 10px -10px rgba(0, 0, 0, 0.2) !default;

$cap-notification-circle-text-align: center !default;
$cap-notification-circle-border-radius: 50% !default;
$cap-notification-circle-line-height: $cap-notification-bar-circle-size !default;
$cap-notification-bar-circle-position: absolute !default;
$cap-notification-circle-font-size: 0.65rem !default;
$cap-notification-circle-font-weight: 700 !default;

$cap-notification-bar-text-font-weight: 600 !default;
$cap-notification-bar-text-margin-bottom: 0 !default;
$cap-notification-bar-text-strong-color: var(--text-color-2) !default;
$cap-notification-bar-text-scroll: scroll !default;
$cap-notification-item-list-style: none !default;
$cap-notification-item-margin: 1rem 0 !default;
$cap-notification-trash-icon-width: $cap-notification-bar-square-size !default;
$cap-notification-trash-icon-height: $cap-notification-bar-square-size !default;
$cap-notification-trash-icon-line-height: $cap-notification-bar-square-size !default;
$cap-notification-trash-icon-cursor: pointer !default;
$cap-notification-trash-icon-color: var(--cap-primary-color) !default;
$cap-notification-trash-border: solid 1px var(--cap-primary-color) !default;
$cap-notification-trash-text-align: center !default;
$cap-notification-trash-border-radius: 4px !default;
$cap-notification-trash-margin: 1.5rem 1.5rem 1rem 0 !default;

$cap-notification-bar-item-card-display: flex !default;
$cap-notification-bar-item-card-align-items: center !default;
$cap-notification-bar-item-card-padding: 1rem !default;
$cap-notification-bar-item-card-margin-top: 1rem !default;
$cap-notification-bar-item-card-background-color: white !default;
$cap-notification-bar-item-card-border-radius: 4px !default;

$cap-notification-bar-item-card-status-icon-text-align: center !default;
$cap-notification-bar-item-card-status-icon-font-size: 1.3rem !default;
$cap-notification-bar-item-card-status-icon-margin-right: 1rem !default;
$cap-notification-bar-item-card-status-icon-line-height: 2.6rem !default;
$cap-notification-bar-item-card-status-icon-color: white !default;
$cap-notification-bar-item-card-word-break: break-word !default;
$cap-notification-bar-item-card-line-height: 1em !default;

$cap-notification-bar-item-card-title-font-weight: bold !default;
$cap-notification-bar-item-card-title-font-size: 18px !default;
$cap-notification-bar-item-card-title-margin-bottom: 0 !default;
$cap-notification-bar-item-card-title-color: var(--text-color-2) !default;

$cap-notification-bar-item-card-subtitle-font-weight: 500 !default;
$cap-notification-bar-item-card-subtitle-font-size: 12px !default;
$cap-notification-bar-item-card-subtitle-margin-bottom: 0.35rem !default;
$cap-notification-bar-item-card-subtitle-margin-top: 0.25rem !default;
$cap-notification-bar-item-card-subtitle-color: var(--text-color-3) !default;

$cap-notification-bar-item-card-body-margin-bottom: 0 !default;
$cap-notification-bar-item-card-body-padding: 0 !default;
$cap-notification-bar-item-card-body-line-height: 1em !default;
$cap-notification-bar-item-card-body-font-size: 13px !default;
$cap-notification-bar-item-card-body-color: var(--text-color-2) !default;
$cap-notification-unread-border: solid 1px var(--cap-primary-color) !default;
$cap-notification-tabs-padding-left: 10px !default;
$cap-notification-tabs-padding-right: 10px !default;
$cap-notification-tabs-col-padding-left: 5px !default;
$cap-notification-tabs-col-padding-right: 5px !default;

$cap-notification-tabs-tab-border: 1px solid var(--cap-primary-color) !default;
$cap-notification-tabs-tab-padding: 10px 15px !default;
$cap-notification-tabs-background-color: white !default;
$cap-notification-tabs-border-radius: 4px !default;
$cap-notification-tabs-text-align: center !default;
$cap-notification-tabs-font-size: 15px !default;
$cap-notification-tabs-font-weight: bold !default;
$cap-notification-tabs-transition: all ease 200ms !default;
$cap-notification-tabs-white-space: nowrap !default;
$cap-notification-tabs-margin-bottom: 10px !default;

$cap-notification-tabs-tab-selected-background-color: var(--cap-primary-color) !default;
$cap-notification-tabs-tab-selected-color: $cap-white !default;

// Forms
$cap-forms-placeholder-text-color: #5f6062 !default;
$cap-form-element-label-font-weight: 600 !default;
$cap-form-element-label-color: var(--primary-text) !default;

$cap-form-element-line-height: 26px !default;
$cap-form-element-table-display: table !default;
$cap-form-element-table-width: 100% !default;

$cap-form-element-table-first-child-width: 30% !default;
$cap-form-element-table-first-child-display: table-cell !default;
$cap-form-element-table-first-child-vertical-align: top !default;

$cap-form-element-table-label: 12px !default;
$cap-form-element-table-input-height: 26px !default;

// Horizontal Navbar
$cap-horizontal-navbar: #333 !default;
$cap-horizontal-navbar-width: 100% !default;
$cap-horizontal-navbar-z-index: 9 !default;
$cap-horizontal-navbar-padding: 0 !default;
$cap-horizontal-navbar-background-color: $cap-white !default;
$cap-horizontal-navbar-margin: 0 auto !default;

$cap-horizontal-navbar-nav-item-font-size: 1em !default;
$cap-horizontal-navbar-nav-item-font-weight: 600 !default;
$cap-horizontal-navbar-nav-item-border-bottom: 2px solid transparent !default;
$cap-horizontal-navbar-nav-item-color: $cap-horizontal-navbar !default;
$cap-horizontal-navbar-nav-item-width: 120px !default;
$cap-horizontal-navbar-nav-item-position: relative !default;

$cap-horizontal-navbar-nav-item-dropdown-menu-text-align: left !default;
$cap-horizontal-navbar-nav-item-dropdown-menu-width: 100% !default;

$cap-horizontal-navbar-nav-item-dropdown-menu-text-font-size: 1em !default;
$cap-horizontal-navbar-nav-item-dropdown-menu-text-font-weight: bold !default;
$cap-horizontal-navbar-nav-item-dropdown-menu-text-padding: 10px 10px 10px 35px !default;
$cap-horizontal-navbar-nav-item-dropdown-menu-text-border-bottom: 1px solid var(--cap-primary-color) !default;

$cap-horizontal-navbar-nav-item-dropdown-menu-text-hover-background-color: var(--cap-primary-color) !default;
$cap-horizontal-navbar-nav-item-dropdown-menu-text-hover-color: $cap-white !default;

$cap-horizontal-navbar-nav-item-dropdown-menu-icon-float: right !default;
$cap-horizontal-navbar-nav-item-dropdown-menu-icon-margin-top: 3px !default;

$cap-horizontal-navbar-nav-item-overlay-background: var(--cap-primary-color) !default;
$cap-horizontal-navbar-nav-item-overlay-z-index: -1 !default;
$cap-horizontal-navbar-nav-item-overlay-opacity: 0.03 !default;

$cap-horizontal-navbar-nav-bar-gradient-width: 75px !default;
$cap-horizontal-navbar-nav-item-overlay-background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 50%, rgba(255, 255, 255, 1) 100%) !default;
$cap-horizontal-navbar-icon-color: var(--cap-primary-color-shadow);

// Image Viewer
$cap-image-viewer-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.5) !default;
$cap-image-viewer-navigator-desktop-spacing: 24px !default;
$cap-image-viewer-navigator-table-spacing: -25px !default;
$cap-image-navigator-mobile-spacing: 5px !default;
$cap-image-viewer-selector-color: #4c4c4c !default;

$cap-image-viewer-position: fixed !default;
$cap-image-viewer-width: 100% !default;
$cap-image-viewer-height: 100% !default;
$cap-image-viewer-left: 0 !default;
$cap-image-viewer-z-index: 1000 !default;
$cap-image-viewer-background: rgba(51, 51, 51, 0.6) !default;

$cap-image-viewer-header-padding: 18px 25px !default;
$cap-image-viewer-header-display: flex !default;
$cap-image-viewer-header-justify-content: space-between !default;
$cap-image-viewer-actions-button-background-color: #f6f6f6;

$cap-image-viewer-title-font-weight: 900 !default;
$cap-image-viewer-header-closer-margin-left: 22px !default;
$cap-image-viewer-header-preview-wrapper-width: 100% !default;
$cap-image-viewer-header-preview-wrapper-height: 100% !default;
$cap-image-viewer-header-preview-wrapper-box-shadow: $cap-image-viewer-shadow !default;
$cap-image-viewer-header-preview-wrapper-overflow: auto !default;
$cap-image-viewer-header-preview-wrapper-background: $cap-white !default;

$cap-image-viewer-body-wrapper-display: flex !default;
$cap-image-viewer-body-wrapper-background: $cap-black !default;
$cap-image-viewer-body-wrapper-padding: 0 27px !default;
$cap-image-viewer-body-wrapper-border-radius: 5px !default;
$cap-image-viewer-body-wrapper-box-shadow: $cap-image-viewer-shadow !default;
$cap-image-viewer-body-wrapper-color: #4c4c4c !default;
$cap-image-viewer-body-wrapper-align-items: center !default;

$cap-image-viewer-body-container-background: $cap-white !default;

// Range Filters
$cap-range-filters-label-font-weight: 600 !default;
$cap-range-filters-label-font-size: 14px !default;
$cap-range-filters-label-color: var(--primary-text) !default;

$cap-range-filters-overlay-panel-z-index: 100 !default;
$cap-range-filters-overlay-panel-box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.1) !default;
$cap-range-filters-overlay-panel-background-color: #fff !default;
$cap-range-filters-overlay-panel-border-radius: 3px !default;
$cap-range-filters-overlay-panel-min-width: 200px !default;

$cap-range-filters-button-background-color: #fff !default;
$cap-range-filters-button-color: var(--primary-text) !default;
$cap-range-filters-button-border: 1px solid var(--cap-primary-color) !default;
$cap-range-filters-button-border-radius: 20px !default;
$cap-range-filters-button-height: $cap-input-height !default;
$cap-range-filters-button-font-size: 1em !default;
$cap-range-filters-button-cursor: pointer !default;
$cap-range-filters-button-padding: 5px 0 5px 22px !default;
$cap-range-filters-button-display: flex !default;
$cap-range-filters-button-flex-direction: column !default;
$cap-range-filters-button-justify-content: center !default;

$cap-range-filters-button-pre-value-icon-color: var(--cap-primary-color) !default;
$cap-range-filters-button-pre-value-icon-opacity: 0.5 !default;
$cap-range-filters-button-pre-value-icon-margin-right: 8px !default;

$cap-range-filters-button-icon-chevron-color: #5f6062 !default;
$cap-range-filters-button-icon-chevron-opacity: 1 !default;
$cap-range-filters-button-icon-chevron-font-size: 1em !default;
$cap-range-filters-button-icon-chevron-margin-right: 12px !default;
$cap-range-filters-button-icon-chevron-margin-top: 3px !default;
$cap-range-filters-button-padding-left: 27px !default;

// Row Action
$cap-row-action-position: relative !default;
$cap-row-action-text-align: right !default;

$cap-row-action-items-padding: 0 !default;
$cap-row-action-items-list-style: none !default;
$cap-row-action-items-box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.15) !default;
$cap-row-action-items-background-color: $cap-white !default;
$cap-row-action-items-position: absolute !default;
$cap-row-action-items-text-align: left !default;
$cap-row-action-items-right: 0 !default;
$cap-row-action-items-margin: 10px 0 0 !default;
$cap-row-action-items-z-index: 1 !default;
$cap-row-action-items-border-radius: 3px !default;
$cap-row-action-items-visibility: hidden !default;
$cap-row-action-items-height: 0 !default;
$cap-row-action-items-overflow: hidden !default;
$cap-row-action-items-cursor: pointer !default;

$cap-row-action-items-open-visibility: visible !default;
$cap-row-action-items-open-height: auto !default;
$cap-row-action-items-open-overflow: visible !default;

$cap-row-action-item-display: flex !default;
$cap-row-action-item-cursor: pointer !default;
$cap-row-action-item-padding: 10px 12px !default;
$cap-row-action-item-border-bottom: 1px solid #ddd !default;
$cap-row-action-item-hover-background-color: #f9f9f9 !default;
$cap-row-action-item-last-child-border: none !default;

$cap-row-action-items-icon-font-size: 18px !default;
$cap-row-action-items-icon-margin: auto 14px auto 0 !default;
$cap-row-action-items-icon-color: var(--cap-primary-color) !default;
$cap-row-action-items-icon-margin: 0 !default;

$cap-row-action-items-info-margin: auto 0 !default;
$cap-row-action-items-info-text-margin: 0 !default;

$cap-row-action-items-info-title-font-size: 14px !default;
$cap-row-action-items-info-title-font-weight: 700 !default;
$cap-row-action-items-info-title-font-size: 12px !default;

// Scanner
$cap-scanner-header-height: 4rem !default;
$cap-scanner-angle-size: 3rem !default;
$cap-scanner-margin-x: 15% !default;

$cap-scanner-scan-container-width: 100% !default;
$cap-scanner-scan-container-height: 100% !default;
$cap-scanner-scan-container-position: absolute !default;
$cap-scanner-scan-container-top: 50% !default;
$cap-scanner-scan-container-left: 50% !default;
$cap-scanner-scan-container-transform: translate(-50%, -50%) !default;
$cap-scanner-scan-background-color: #fff !default;

$cap-scanner-scan-header-color: var(--cap-primary-color) !default;
$cap-scanner-scan-header-font-size: 2rem !default;
$cap-scanner-scan-header-height: $cap-scanner-header-height !default;
$cap-scanner-scan-header-box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.15) !default;
$cap-scanner-scan-header-background-color: #fff !default;
$cap-scanner-scan-header-cursor: pointer !default;
$cap-scanner-scan-header-hover-font-size: 1.1em !default;
$cap-scanner-scan-header-hover-filter: grayscale(20%) !default;

$cap-scanner-scan-message-position: absolute !default;
$cap-scanner-scan-message-right: 0 !default;
$cap-scanner-scan-message-left: 0 !default;
$cap-scanner-scan-message-z-index: 1 !default;
$cap-scanner-scan-message-height: 3rem !default;
$cap-scanner-scan-message-max-width: 22rem !default;
$cap-scanner-scan-message-border-radius: 25px !default;
$cap-scanner-scan-message-box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.15) !default;
$cap-scanner-scan-message-background-color: #fff !default;

$cap-scanner-scan-message-text-overflow: hidden !default;
$cap-scanner-scan-message-font-size: 1rem !default;

$cap-scanner-zxing-position: absolute !default;
$cap-scanner-zxing-top: $cap-scanner-header-height !default;
$cap-scanner-zxing-height: calc(100% - #{$cap-scanner-header-height}) !default;
$cap-scanner-zxing-width: 100vw !default;
$cap-scanner-zxing-z-index: 1 !default;

$cap-scanner-zxing-video-width: 100% !default;
$cap-scanner-zxing-video-height: 100% !default;

$cap-scanner-error-message-position: relative !default;
$cap-scanner-error-message-z-index: 1 !default;
$cap-scanner-error-message-top: 45vh !default;

$cap-scanner-scan-list-strikethrough-text-decoration: line-through !default;
$cap-scanner-scan-list-text-color: var(--error-color) !default;
$cap-scanner-scan-list-text-cursor: pointer !default;
$cap-scanner-scan-list-text-font-size: 1.2rem !default;
$cap-scanner-scan-list-text-hover-color: #f00 !default;
$cap-scanner-scan-list-text-hover-font-size: 1.3em !default;

// Input Mask
$cap-input-mask-width: 100% !default;
$cap-input-mask-text-width: 100% !default;

// Inputs
$cap-input-group-addon-background-color: $cap-white !default;
$cap-input-group-addon-color: var(--primary-text) !default;
$cap-input-group-addon-border-color: #ddd !default;
$cap-input-group-addon-max-height: $cap-input-height !default;
$cap-input-group-addon-height: auto !default;
$cap-input-group-addon-white-space: nowrap !default;

$cap-input-text-border-radius: 3px !default;
$cap-input-text-padding-left: 11px !default;
$cap-input-text-font-size: 1em !default;
$cap-input-text-font-family: var(--primary-font) !default;
$cap-input-text-color: var(--primary-text) !default;
$cap-input-text-border: 1px solid $input-border-color !default;
$cap-input-text-height: $cap-input-height !default;
$cap-input-text-display: flex !default;
$cap-input-text-flex-grow: 1 !default;

$cap-input-text-rounded-border-radius: 20px !default;
$cap-input-text-rounded-border-color: var(--cap-primary-color) !default;
$cap-input-text-rounded-height: $cap-input-height !default;
$cap-input-text-rounded-width: 100% !default;

// Links
$cap-link-color: var(--cap-primary-color) !default;
$cap-link-font-size: 1em !default;
$cap-link-font-weight: 600 !default;

// Card
$cap-card-border-radius: 8px !default;
$cap-card-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !default;
$cap-card-border: solid 1px $cap-light-gray !default;
$cap-card-background-color: $cap-white !default;
$cap-card-padding: 20px !default;

$cap-card-header-border-bottom: 1px solid $cap-light-gray !default;
$cap-card-header-padding-bottom: 10px !default;
$cap-card-header-font-size: 22px !default;
$cap-card-header-font-weight: 600 !default;
$cap-card-header-title-rs-text-align: right !default;
$cap-card-header-settings-margin-left: 5px !default;
$cap-card-header-settings-color: var(--cap-primary-color) !default;
$cap-card-header-settings-width: 2rem !default;
$cap-card-header-settings-height: 2rem !default;
$cap-card-header-settings-line-height: 2rem !default;
$cap-card-header-settings-text-align: center !default;

$cap-card-content-gutter-margin-top: 25px !default;

// Accordion
$cap-accordion-header-not-active-background-color: $cap-white !default;
$cap-accordion-header-not-active-color: $cap-dark-gray !default;
$cap-accordion-header-not-active-toggle-icon-color: $cap-dark-gray !default;
$cap-accordion-header-not-active-hover-bow-shadow: 0 5px 0 $cap-white, 0 1px 3px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.1) !default;
$cap-accordion-header-not-active-background-color: $cap-white !default;
$cap-accordion-header-not-active-color: $cap-black !default;
$cap-accordion-header-not-active-color-toggle-icon: $cap-black !default;

$cap-accordion-header-color: var(--primary-text) !default;
$cap-accordion-header-font-size: 16px !default;
$cap-accordion-header-font-weight: 700 !default;
$cap-accordion-header-height: 64px !default;
$cap-accordion-header-border-radius: 3px !default;
$cap-accordion-header-bow-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1) !default;
$cap-accordion-header-background-color: $cap-white !default;
$cap-accordion-header-padding: 0 1em !default;
$cap-accordion-header-line-height: 64px !default;
$cap-accordion-header-transition: background-color 0.2s !default;

$cap-accordion-content-border-br-radius: 3px !default;
$cap-accordion-content-border-bl-radius: 3px !default;
$cap-accordion-content-padding: 0 1em 1em 0.57em !default;
$cap-accordion-content-background-color: $cap-white !default;
$cap-accordion-content-color: var(--primary-text) !default;
$cap-accordion-content-wrapper-border-br-radius: 3px !default;
$cap-accordion-content-wrapper-border-bl-radius: 3px !default;
$cap-accordion-content-wrapper-box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1) !default;

$cap-accordion-tab-header-font-size: 16px !default;
$cap-accordion-header-title-font-size: 13px !default;

$cap-accordion-tab-header-block-width: calc(100% - 2em) !default;
$cap-accordion-header-font-size: 15px !default;
$cap-accordion-header-status-font-size: 13px !default;
$cap-accordion-toggle-icon-float: right !default;
$cap-accordion-toggle-icon-transform: translateY(150%) !default;

// Accordion card.
$cap-accordion-card-header-width: 50% !default;
$cap-accordion-card-header-left-display: flex !default;
$cap-accordion-card-header-left-text-align: right !default;
$cap-accordion-card-title-font-weight: bold !default;
$cap-accordion-card-title-font-size: 16px !default;
$cap-accordion-card-title-min-height: 17px !default;
$cap-accordion-card-title-icon-color: var(--cap-primary-color) !default;
$cap-accordion-card-title-icon-margin-right: 10px !default;

$cap-accordion-card-actions-margin-left: 10px !default;
$cap-accordion-card-actions-transition: all 0.5s ease !default;
$cap-accordion-card-actions-hover: scale(1.1) !default;

$cap-accordion-card-toggler-font-size: 14px !default;
$cap-accordion-card-toggler-color: var(--cap-primary-color) !default;
$cap-accordion-card-toggler-font-size: 10px !default;

$cap-accordion-card-header-display: flex !default;
$cap-accordion-card-header-justify-content: space-between !default;
$cap-accordion-card-header-padding: 15px 20px !default;
$cap-accordion-card-header-border-radius: 3px !default;
$cap-accordion-card-header-background-color: $cap-white !default;

$cap-accordion-card-content-border-bl-radius: 3px !default;
$cap-accordion-card-content-border-br-radius: 3px !default;
$cap-accordion-card-content-transition: all 0.25s ease !default;
$cap-accordion-card-content-background-color: $cap-white !default;
$cap-accordion-card-content-open-padding: 0 15px 20px !default;

$cap-accordion-card-delimiter-margin-bottom: 15px !default;
$cap-accordion-card-delimiter-background: #ddd !default;

// Autocomplete
$cap-dropdown-hover-color: #eaeaea !default;
$cap-autocomplete-button-background-color: $cap-white !default;
$cap-autocomplete-button-color: $cap-black !default;
$cap-autocomplete-button-border: 1px solid $input-border-color !default;

$cap-autocomplete-input-height: 38px !default;
$cap-autocomplete-input-border: 1px solid $input-border-color !default;
$cap-autocomplete-input-padding: 0.4em !default;
$cap-autocomplete-input-error-border-color: $input-border-color !default;

$cap-autocomplete-button-width: 2em !default;
$cap-autocomplete-highlighted-option-background-color: $cap-dropdown-hover-color !default;
$cap-autocomplete-highlighted-option-color: var(--primary-text) !default;

$cap-autocomplete-button-hover-background-color: $cap-white !default;
$cap-autocomplete-button-hover-color: $cap-black !default;
$cap-autocomplete-button-border-color: $input-border-color !default;

// Button
$cap-button-disabled-background: #ddd !default;
$cap-button-disabled-text-color: #fff !default;
$cap-button-label-color: #fff !default;
$cap-button-success-text-color: #fff !default;
$cap-button-success-background: $cap-success !default;
$cap-button-warning-text-color: #fff !default;
$cap-button-warning-background: $cap-warning !default;
$cap-button-error-text-color: #fff !default;
$cap-button-error-background: $cap-danger !default;
$cap-button-secondary-text-color: var(--cap-primary-color) !default;
$cap-button-secondary-background: #fff !default;
$cap-button-tertiary-text-color: var(--cap-primary-color) !default;
$cap-button-tertiary-background: #f0f0f0 !default;

// Calendar
$cap-calendar-input-border-radius: 20px !default;
$cap-calendar-input-height: $cap-input-height !default;
$cap-calendar-datepicker-border-bottom: 1px solid $input-border-color !default;
$cap-calendar-datepicker-title-font-family: var(--primary-font) !default;
$cap-calendar-datepicker-title-font-size: 17px !default;
$cap-calendar-datepicker-title-font-weight: 600 !default;
$cap-calendar-datepicker-title-text-align: center !default;
$cap-calendar-datepicker-title-color: var(--primary-text) !default;

$cap-calendar-datepicker-prev-next-color: #5f6062 !default;
$cap-calendar-datepicker-trigger-button-border: 1px solid $input-border-color !default;
$cap-calendar-datepicker-trigger-background: var(--cap-primary-color) !default;
$cap-calendar-datepicker-trigger-color: $cap-white !default;
$cap-calendar-datepicker-trigger-width: $cap-input-height !default;
$cap-calendar-datepicker-trigger-height: $cap-input-height !default;
$cap-calendar-datepicker-trigger-icon-left: 2em !default;

$cap-calendar-input-text-width: calc(100% - 2.357em) !default; /* stylelint-disable-line number-max-precision */
$cap-calendar-input-text-height: $cap-input-height !default;

$cap-calendar-input-border: 1px solid $input-border-color !default;
$cap-calendar-input-height: $cap-input-height !default;
$cap-calendar-input-width: calc(100% - #{$cap-input-height}) !default;
$cap-calendar-input-error-border: 1px solid $input-border-color !default;
$cap-calendar-input-error-border-right: 0 none !default;

$cap-calendar-datepicker-widget-content-text-align: center !default;
$cap-calendar-datepicker-widget-content-state-default-width: 34.5714px !default; /* stylelint-disable-line number-max-precision */
$cap-calendar-datepicker-widget-content-state-default-border-radius: 50% !default;
$cap-calendar-datepicker-widget-content-state-active-background-color: var(--cap-primary-color) !default;
$cap-calendar-datepicker-widget-content-state-active-color: $cap-white !default;
$cap-calendar-datepicker-widget-content-state-not-active-background-color: var(--cap-primary-color) !default;
$cap-calendar-datepicker-widget-content-state-not-active-color: $cap-white !default;

$cap-calendar-input-hover-border-color: var(--cap-primary-color) !default;
$cap-calendar-input-hover-border-tl-radius: $cap-calendar-input-border-radius !default;
$cap-calendar-input-hover-border-bl-radius: $cap-calendar-input-border-radius !default;
$cap-calendar-input-hover-border-height: $cap-calendar-input-height !default;

$cap-calendar-input-hover-trigger-border-color: var(--cap-primary-color) !default;
$cap-calendar-input-hover-trigger-border-tr-radius: $cap-calendar-input-border-radius !default;
$cap-calendar-input-hover-trigger-border-br-radius: $cap-calendar-input-border-radius !default;
$cap-calendar-input-hover-trigger-height: $cap-calendar-input-height !default;
$cap-calendar-input-hover-trigger-font-size: 0.8em !default;
$cap-calendar-input-hover-trigger-padding-top: 1px !default;
$cap-calendar-input-hover-trigger-button-icon-left-font-size: 1.5em !default;

$cap-calendar-double-date-width: 105% !default;

$cap-calendar-w-btn-hover-border: 1px solid var(--cap-primary-color) !default;
$cap-calendar-w-btn-hover-border-radius: 20px !default;
$cap-calendar-w-btn-hover-background-color: $cap-white !default;
$cap-calendar-w-btn-hover-height: $cap-calendar-input-height !default;

$cap-calendar-w-btn-hover-datepicker-background-color: $cap-white !default;
$cap-calendar-w-btn-hover-datepicker-color: var(--cap-primary-color) !default;
$cap-calendar-w-btn-hover-datepicker-left: 4px !default;
$cap-calendar-w-btn-hover-datepicker-border-radius: 20px 0 0 20px !default;
$cap-calendar-w-btn-hover-datepicker-opacity: 0.5 !default;
$cap-calendar-w-btn-hover-datepicker-width: 21px !default;

$cap-calendar-w-btn-hover-input-left: 21px !default;
$cap-calendar-w-btn-hover-input-width: calc(100% - 21px) !default;
$cap-calendar-w-btn-hover-input-border-radius: 0 20px 20px 0 !default;

// Time picker
$cap-timepicker-input-hover-border-color: #a6a6a6 !default;

// Checkbox filter
$cap-checkbox-filter-height: $cap-dynamic-filter-height !default;
$cap-checkbox-filter-border: 1px solid $input-border-color !default;
$cap-checkbox-filter-border-radius: 25px !default;
$cap-checkbox-filter-padding: 5px 20px 0 !default;
$cap-checkbox-filter-justify-content: center !default;
$cap-checkbox-filter-background-color: $cap-white !default;
$cap-checkbox-filter-icon-left-padding: 12px !default;

$cap-checkbox-filter-label-padding: 0 10px !default;
$cap-checkbox-filter-label-color: #5f6062 !default;
$cap-checkbox-filter-label-width: 80% !default;

// Checkbox
$cap-checkbox-border-color: $input-border-color !default;
$cap-checkbox-border-color-hover: $input-border-color !default;
$cap-checkbox-state-active-background: $cap-white !default;
$cap-checkbox-state-active-border-color: var(--cap-primary-color) !default;
$cap-checkbox-state-active-icon-margin-top: 1px !default;
$cap-checkbox-state-active-icon-font-weight: 900 !default;
$cap-checkbox-state-active-icon-font-size: 16px !default;

// Chips
$cap-chips-width-hover: 100% !default;
$cap-chips-border-color-hover: $input-border-color !default;
$cap-chips-border-padding-hover: .4rem !default;
$cap-chips-token-padding: .25rem .75rem !default;
$cap-chips-token-background-color: $cap-platinum !default;
$cap-chips-token-color: #5f6062 !default;
$cap-chips-token-border-color: $input-border-color !default;

// Collapsable-card
$cap-collapsable-card-nav-divider-color: #e9ecef !default;
$cap-collapsable-card-header-border-bottom: #e9ecef !default;
$cap-collapsable-card-padding-top: 10px !default;
$cap-collapsable-card-padding: $cap-collapsable-card-padding-top 20px 20px 20px !default;
$cap-collapsable-card-title-font-size: 20px !default;
$cap-collapsable-card-title-font-weight: bold !default;

$cap-collapsable-card-header-justify-content: space-between !default;

$cap-collapsable-card-link-color: var(--cap-primary-color) !default;
$cap-collapsable-card-link-font-weight: 600 !default;
$cap-collapsable-card-content-padding-top: $cap-collapsable-card-padding-top !default;

// Color-picker
$cap-colorpicker-input-display: inline-flex !default;
$cap-colorpicker-input-border-left: none !default;
$cap-colorpicker-with-input-border-right: none !default;
$cap-colorpicker-preview-width: 38px !default;
$cap-colorpicker-preview-height: 38px !default;

// Select Button
$cap-select-button-border-color: var(--cap-primary-color) !default;
$cap-select-button-background-color: $cap-white !default;
$cap-select-button-border-color: var(--cap-primary-color) !default;
$cap-select-button-color: var(--cap-primary-color) !default;
$cap-select-button-font-weight: bold !default;
$cap-select-button-padding: 5px 25px !default;
$cap-select-button-font-size: 1em !default;
$cap-select-button-hover-text-decoration: underline !important !default;
$cap-select-button-active-background-color: var(--cap-primary-color) !default;
$cap-select-button-active-color: $cap-white !default;

// Slide up panel
$cap-slide-up-panel-transition: height 0.3s ease-out 0s !default;
$cap-slide-up-panel-border-radius: 10px !default;
$cap-slide-up-panel-box-shadow: 0 -6px 13px 0 rgba(0, 0, 0, 0.15) !default;
$cap-slide-up-panel-position: fixed !default;
$cap-slide-up-panel-right: 0 !default;
$cap-slide-up-panel-bottom: 0 !default;
$cap-slide-up-panel-left: 0 !default;
$cap-slide-up-panel-z-index: 50 !default;
$cap-slide-up-panel-background-color: $cap-white !default;
$cap-slide-up-panel-overflow: scroll !default;

$cap-slide-up-panel-drag-handle-bar-cursor: grab !default;
$cap-slide-up-panel-drag-handle-bar-active-cursor: grabbing !default;

$cap-slide-up-panel-handle-bar-height: 0.33rem !default;
$cap-slide-up-panel-handle-bar-width: 2.5rem !default;
$cap-slide-up-panel-handle-bar-border-radius: 2.5px !default;
$cap-slide-up-panel-handle-bar-background-color: $cap-white !default;

$toast-background-color: #fff !default;
$toast-text-color: #333 !default;
$toast-background-color-success: $toast-background-color !default;
$toast-background-color-warning: $toast-background-color !default;
$toast-background-color-info: $toast-background-color !default;
$toast-background-color-error: $toast-background-color !default;
$toast-summary-font-size: 18px !default;
$toast-detail-font-size: 14px !default;
$toast-detail-text-color: #4c4c4c !default;
$toast-close-icon-color: rgba(51, 51, 51, 0.15) !default;
$toast-border-radius: 5px !default;

//Week switcher
$cap-week-switcher-divider-border: 0.5px solid #ddd !default;
$cap-week-switcher-border: 2px solid var(--cap-primary-color) !default;
$cap-week-switcher-border-radius: 3px !default;
$cap-week-switcher-display: inline-block !default;
$cap-week-switcher-content-display: inline-block !default;
$cap-week-switcher-content-color: var(--cap-primary-color) !default;
$cap-week-switcher-content-font-size: 16px !default;
$cap-week-switcher-content-font-weight: bold !default;
$cap-week-switcher-button-divider-padding: 10px 0 !default;
$cap-week-switcher-button-divider-width: 120px !default;
$cap-week-switcher-button-divider-text-align: center !default;
$cap-week-switcher-button-divider-border-right: $cap-week-switcher-divider-border !default;
$cap-week-switcher-button-divider-border-left: $cap-week-switcher-divider-border !default;
$cap-week-switcher-button-not-disabled-padding: 0 15px !default;

//Timeline
$cap-timeline-cards-divider-padding-left: 20px !default;
$cap-timeline-cards-divider-position: relative !default;
$cap-timeline-cards-divider-before-position: absolute !default;
$cap-timeline-cards-divider-before-top: 0 !default;
$cap-timeline-cards-divider-before-left: 0 !default;
$cap-timeline-cards-divider-before-width: 1px !default;
$cap-timeline-cards-divider-before-height: 100% !default;
$cap-timeline-cards-divider-before-border-left: 2px dashed var(--cap-primary-color) !default;
$cap-timeline-card-margin-bottom: 10px !default;
$cap-timeline-card-last-of-type-margin-bottom: 0 !default;
$cap-timeline-content-padding: 10px 15px !default;
$cap-timeline-content-border-radius: 3px !default;
$cap-timeline-content-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !default;
$cap-timeline-content-background-color: #fff !default;
$cap-timeline-created-color: rgba(51, 51, 51, 0.5) !default;
$cap-timeline-created-font-size: 13px !default;
$cap-timeline-title-font-weight: bold !default;
$cap-timeline-title-color: var(--primary-text) !default;
$cap-timeline-title-font-size: 15px !default;
$cap-timeline-comment-font-size: 15px !default;
$cap-timeline-comment-color: var(--text-color-2) !default;
$cap-timeline-comment-link-color: var(--cap-primary-color) !default;

//Textarea
$cap-textarea-width: 100% !default;
$cap-textarea-resize: vertical !default;
$cap-textarea-border-color: $input-border-color !default;
$cap-textarea-border-radius: 3px !default;
$cap-textarea-border-height: 100px !default;
$cap-textarea-padding: 10px !default;
$cap-textarea-font-size: 14px !default;
$cap-textarea-font-color: var(--primary-text) !default;

// Table
$cap-table-border-color: #ddd !default;
$cap-table-text-color: #5f6062 !default;
$cap-table-sort-icon-color: #ddd;
$cap-table-actions-col-width: 80px !default;
$cap-table-expand-col-width: 30px !default;
$cap-table-header-table-data-background-color: $cap-white !default;
$cap-table-header-table-data-color: #5f6062 !default;
$cap-table-header-table-border: none !default;
$cap-table-header-table-font-size: 1em !default;
$cap-table-header-table-padding: 0.571em 0 0.857em 0 !default; /* stylelint-disable-line number-max-precision */

$cap-table-header-font-weight: 600 !default;
$cap-table-header-color: #333 !default;
$cap-table-header-padding: 0.571em 0 0.857em 0 !default; /* stylelint-disable-line number-max-precision */
$cap-table-header-hover-background-color: $cap-white !default;

$cap-table-caption-background-color: $cap-white !default;
$cap-table-caption-border: none !default;

$cap-table-inputgroup-addon-border-right: none !default;
$cap-table-inputgroup-addon-color: var(--cap-primary-color) !default;

$cap-table-actions-wrapper-max-width: 100px !default;
$cap-table-actions-icon-color: var(--cap-primary-color) !default;
$cap-table-actions-margin-right: 10px !default;
$cap-table-actions-cursor: pointer !default;
$cap-table-actions-right-padding-left: 10px !default;

$cap-table-sortable-column-icon-color: var(--cap-primary-color) !important !default;

$cap-table-paginator-margin-top: 10px !default;
$cap-table-paginator-background-color: transparent !default;
$cap-table-paginator-border: none !default;
$cap-table-paginator-text-align: right !default;
$cap-table-paginator-bottom-text-align: right !default;
$cap-table-paginator-page-color: var(--cap-primary-color) !default;
$cap-table-paginator-font-weight: normal !default;
$cap-table-paginator-element-background: $cap-white !default;
$cap-table-paginator-element-color: var(--cap-primary-color) !default;

$cap-table-paginator-prev-next-width: 25px !default;
$cap-table-paginator-prev-next-line-height: 2rem !default;
$cap-table-paginator-prev-next-font-size: 1rem !default;

$cap-table-paginator-prev-margin-right: -1px !default;
$cap-table-paginator-prev-border-tl-radius: 3px !default;
$cap-table-paginator-prev-border-left-radius: 3px !default;

$cap-table-paginator-next-margin-left: -2px !default;
$cap-table-paginator-next-border-tr-radius: 3px !default;
$cap-table-paginator-next-border-br-radius: 3px !default;

$cap-table-filter-toggle-font-size: 0.8em !default;
$cap-table-filter-toggle-cursor: pointer !default;

$cap-table-filter-icon-position: absolute !default;
$cap-table-filter-icon-top: 0 !default;
$cap-table-filter-icon-left: 25px !default;
$cap-table-filter-icon-height: 100% !default;
$cap-table-filter-icon-display: flex !default;
$cap-table-filter-icon-flex-direction: column !default;
$cap-table-filter-icon-flex-justify-content: center !default;
$cap-table-filter-icon-color: var(--cap-primary-color) !default;
$cap-table-filter-icon-opacity: 0.5 !default;
$cap-table-filter-font-size: 0.9em !default;
$cap-table-filter-line-height: 0.9em !default;

$cap-table-reset-filter-position: absolute !default;
$cap-table-reset-filter-top: 0 !default;
$cap-table-reset-filter-right: 23px !default;
$cap-table-reset-filter-height: 100% !default;
$cap-table-reset-filter-display: flex !default;
$cap-table-reset-filter-flex-direction: column !default;
$cap-table-reset-filter-justify-content: center !default;
$cap-table-reset-filter-cursor: pointer !default;
$cap-table-reset-filter-background: $cap-white !default;
$cap-table-reset-filter-color: var(--error-color) !default;
$cap-table-reset-filter-opacity: 1 !default;
$cap-table-reset-filter-font-size: 0.9em !default;
$cap-table-reset-filter-line-height: 0.9em !default;

$cap-table-multiselect-label-padding-left: 27px !default;
$cap-table-multiselect-label-input-text-padding-left: 36px !important !default;

$cap-table-dropdown-display: inline-block !default;

$cap-table-filters-display: block !default;
$cap-table-filters-icon-color: var(--cap-primary-color) !default;
$cap-table-filters-font-size: 0.8em !default;
$cap-table-filters-cursor: pointer !default;

$cap-table-filters-label-display: inline !default;
$cap-table-filters-label-flex-grow: 1 !default;
$cap-table-filters-label-margin-left: 5px !default;
$cap-table-filters-label-color: var(--cap-primary-color) !default;

$cap-table-filters-dialog-width: 50% !default;
$cap-table-filters-settings-padding: 20px 40px !default;

$cap-table-filters-header-display: flex !default;
$cap-table-filters-header-justify-content: center !default;
$cap-table-filters-header-position: relative !default;
$cap-table-filters-header-margin-bottom: 15px !default;

$cap-table-filters-header-close-filters-position: absolute !default;
$cap-table-filters-header-close-filters-right: 0 !default;

$cap-table-filters-items-min-height: 300px !default;
$cap-table-filters-items-max-height: 300px !default;
$cap-table-filters-items-overflow-y: auto !default;

$cap-table-filters-item-width: 100% !default;
$cap-table-filters-item-border: 1px solid $cap-input-border-color !default;
$cap-table-filters-item-padding: 10px !default;
$cap-table-filters-item-border-radius: 3px !default;
$cap-table-filters-item-selected-border-color: var(--success-color) !default;
$cap-table-filters-item-checkbox-border-color: $cap-input-border-color !default;

$cap-table-item-cursor: default !default;
$cap-table-item-form-element-height: 23px !default;
$cap-table-item-label-font-weight: normal !default;
$cap-table-item-label-cursor: pointer !default;

$cap-table-columns-list-width: 10px !default;
$cap-table-columns-list-text-align: right !default;
$cap-table-columns-list-icon-cursor: pointer !default;
$cap-table-columns-list-color: var(--cap-primary-color) !default;

$cap-table-frozen-column-shadow: inset -7px 0 5px -5px $input-border-color;

/* Info table */
$cap-info-table-delimiter: #ddd;
$cap-info-table-header-color: rgba(58, 58, 58, 0.5);
$cap-info-table-header-bg: transparent;
$cap-info-table-header-height: 38px;
$cap-info-table-cell-height: 50px;
$cap-info-table-cell-padding: 10px;
$cap-info-table-cell-border: none;
$cap-info-table-cell-bg: #fff;
$cap-info-table-row-bg: #fff;
$cap-info-table-border-radius: 3px;
$cap-info-table-border-spacing: 0 10px;
$cap-info-table-card-breakpoint: 767.98px;
$cap-info-table-card-gutter: 10px;
$cap-info-table-card-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.2);

/* File preview list */
$cap-file-preview-item-background-color: #f9f9f9;

/* Tab view */
$cap-tab-view-bottom-border-color: #ccc;
$cap-tab-view-text-color: #4f5152;
$cap-tab-view-highlight-tab-background: #f9f9f9;
$cap-tab-view-highlight-tab-border-color: #ccc;

/* User menu */
$cap-user-menu-proportions: 38px;
$cap-user-menu-subtext-color: #4f5152;
$cap-user-menu-hover-color: #f9f9f9;
$cap-tab-view-highlight-tab-border-color: #ccc;

/* Sidebar menu */
$cap-sidebar-menu-initial-width: 250px;
$cap-sidebar-menu-collapsed-width: 90px;
$cap-sidebar-menu-button-proportions: 33px;
$cap-sidebar-menu-delimiter-color: #e4e4e4;

// Amount Input
$cap-amount-input-border: 1px solid var(--cap-primary-color) !default;
$cap-amount-input-border-radius: 3px !default;
$cap-amount-input-text-color: var(--cap-primary-color) !default;

// Form Card
$cap-form-card-border-radius: 10px !default;
$cap-form-card-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !default;
$cap-form-card-border: solid 1px $cap-light-gray !default;
$cap-form-card-background-color: $cap-white !default;
$cap-form-card-padding: 1.5rem !default;

$cap-form-card-header-border-bottom: 1px solid #dddddd !default;
$cap-form-card-header-padding-bottom: 10px !default;
$cap-form-card-header-font-size: 1.25rem !default;
$cap-form-card-header-font-weight: 700 !default;

$cap-form-card-content-padding-top: 26px !default;

$cap-media-breakpoints: (
  xs: '(max-width: 599.98px)',
  sm: '(min-width: 600px) and (max-width: 959.98px)',
  md: '(min-width: 960px) and (max-width: 1279.98px)',
  lg: '(min-width: 1280px) and (max-width: 1919.98px)',
  xl: '(min-width: 1920px)',
  handset: '(max-width: 599.98px) and (orientation: portrait), (max-width: 959.98px) and (orientation: landscape)',
  tablet: '(min-width: 600px) and (max-width: 839.98px) and (orientation: portrait), (min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)',
  web: '(min-width: 840px) and (orientation: portrait), (min-width: 1280px) and (orientation: landscape)',
  handset-portrait: '(max-width: 599.98px) and (orientation: portrait)',
  tablet-portrait: '(min-width: 600px) and (max-width: 839.98px) and (orientation: portrait)',
  web-portrait: '(min-width: 840px) and (orientation: portrait)',
  handset-landscape: '(max-width: 959.98px) and (orientation: landscape)',
  tablet-landscape: '(min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)',
  web-landscape: '(min-width: 1280px) and (orientation: landscape)',
) !default;

/* break points */
$breakpoint-handheld: '(max-width: 599.98px) and (orientation: portrait), (max-width: 959.98px) and (orientation: landscape)';
$breakpoint-tablet: '(min-width: 600px) and (max-width: 839.98px) and (orientation: portrait), (min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)';
