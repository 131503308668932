app-components-dialog {
  cpb-list-renderer-filters {
    .table-filters {
      display: none !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  .cap-info-table.p-datatable {
    .p-datatable-thead th {
      &.th_description {
        width: 50%;
      }
    }
  }
}
