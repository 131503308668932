@import 'abstracts';

app-header {
  .title-bar {
    display: flex;
    padding: 19px 29px 22px 37px;

    span {
      font-size: 1.5rem;
      font-weight: bold;
    }

    .hide {
      display: none;
    }
  }

  a.link-navigate-back-page {
    color: var(--cap-primary-color);
    font-weight: 600;
    cursor: pointer;
  }

  .equal-flex-col {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .flex-right {
    flex-grow: 1;
    flex-shrink: 0;
  }

  .logo {
    img {
      height: 3.69rem;
      width: 6.38rem;
    }
  }

  .switch-user-container {
    margin-right: 10px;

    cap-button p-button {
      button {
        background: $primary;
      }

      span.p-button-icon {
        font-size: 1.25rem;
      }
    }

    .p-button.cap-button {
      width: 50px;
      height: 50px;
    }
  }
}
