@import 'abstracts';

app-navigation-bar {
  cap-sidebar-menu {
    &.hide {
      display: none;
    }
  }

  .cap-sidebar {
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.07);
    border-right: unset;
    width: 175px;

    &__menus li {
      padding: 0 20px 30px;
      border-left: unset;

      &:hover {
        background-color: $white;
      }

      // styles for default buttons
      cap-button p-button {
        button {
          display: flex;
          justify-content: center;
          border-radius: 3px;
          background: $blue;
          border: 1px solid $default-border-color;
          box-shadow: 0 2px 4px 0 $default-border-color;
        }

        span {
          &.p-button-label {
            flex: 0;
          }

          &.p-button-icon {
            margin-right: 10px;
          }
        }

        .cap-button {
          font-size: 1.05rem;
          text-align: center;
          color: $white;
          height: 60px;
        }
      }

      // styles for custom buttons
      .custom-button-1 {
        button {
          background: $success;
        }

        .cap-button {
          height: 101px;
        }
      }

      .disruption-button {
        button {
          background: $warning;
        }
      }
    }

    .cap-sidebar__brand {
      padding: 20px 37px 68px;
    }

    .brand-wrapper {
      height: 83px;
    }
  }

  .cap-sidebar__menus {
    li:has(.hidden) {
      display: none;
    }
  }
}
