@import 'abstracts';

app-add-disruption-type-value {
  .buttons {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $border-color;
    padding-top: 30px;
  }

  .submit-button {
    background: $success;
  }
}
