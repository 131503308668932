@import 'abstracts';

app-add-completed-order-operation-disruptions {
  .buttons {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $border-color;
    padding-top: 30px;
  }

  .save-button {
    background: $success;
    width: 177px;
  }
}
