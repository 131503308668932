@import 'abstracts';
@import 'components/custom-dialog';

@include custom-dialog;

app-stop-order-operation-popup {
  .popup-actions {
    display: flex;
    justify-content: space-between;

    cap-button {
      button {
        height: 60px;

        span.p-button-icon,
        span.p-button-label {
          font-size: 1.25rem;
          font-weight: bold;
        }
      }
    }

    button {
      &.cap-button.danger {
        background-color: $danger;
        border: 2px solid $danger;
      }
    }
  }

  .warning {
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      color: $warning;
      font-size: 1.38rem;
      padding-right: 10px;
    }
  }
}

body .p-dialog {
  @if (selector-exists(app-stop-order-operation-popup)) {
    width: 60%;
  }
}
