/* General colors */
$primary: #d50232;
$primary-light: #f7fcfe;
$liver: #4f5152;
$light-gray: #e7eaec;
$body-background: #f9f9f9;
$gray: #ddd;
$form-divider-title-background: #f7f7f7;
$dark-gray: #333;
$confirmation-header: rgb(0, 92, 170);

/* Fonts */
$font-light: opensans light;
$font-regular: opensans regular;
$font-semi-bold: opensans semibold;
$font-bold: opensans bold;
$font-italic: sourcesanspro-it;

/* Spacing */
$mobile-spacing: 15px;
$dashboard-page-padding: 40px;

/* General */
$body-color: #5f6062;
$border-color: #ddd;
$danger-button-box-shadow: rgba(255, 59, 48, 0.25);
$cap-input-height: 33px;
$placeholder-color: #b5b5b5;
$sidebar-footer-height: 120px;
$sidebar-horizontal-padding: 25px;
$default-border-color: rgba(0, 92, 170, 0.15);
$label-color: #3b3b3b;

/* General sizes */
$nav-height: 4.5rem;
$header-height: 5.62rem;

/* General */
$gray-light: #f9f9f9;
$gray-dark: #3a3a3a;
$gray-dark-secondary: #7a7a7a;
$body-color: #5f6062;
$dark-lava: #333;
$white: #fff;
$black: #000;
$platinum: #e6e6e6;
$chateau-green: #35c44d;
$blue: #005caa;
$success: #86bf00;
$warning: #fa6400;
$danger: #e31d3a;

$cap-input-height: 33px;

$menu-height: 90px;
$mobile-menu-height: 65px;
$table-header-color: #5f6062;
