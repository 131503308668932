@import 'abstracts';

app-add-disruptions-dialog {
  .buttons {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $border-color;
    padding-top: 30px;
  }

  .pause-time {
    display: flex;
    align-items: center;

    i {
      color: $blue;
      font-size: 1rem;
      margin-right: 10px;
    }

    .time {
      font-size: 1.13rem;
      color: $body-color;
    }
  }

  .resume-button {
    background: $success;
    width: 177px;
  }
}
