@import 'abstracts';

app-view-process-order {
  .container {
    padding: 0;
  }

  .content {
    margin-left: 27px;
    margin-right: 20px;
  }

  .add-disruption-button {
    .cap-button {
      height: 60px;
      width: 227px;
    }

    button {
      background: $warning;
      border: 1px solid $warning;
      box-shadow: 0 2px 4px 0 $warning;
    }
  }

  .order-duration {
    cpb-text-input {
      cap-input {
        input {
          cursor: not-allowed;
          background: $white;
          pointer-events: none;
        }

        .p-inputgroup input.p-inputtext {
          border: none;
          color: $body-color;
          font-size: 1.13rem;
          padding-left: 0;
        }
      }
    }
  }

  .disruptions {
    font-size: 1.13rem;

    .disruption {
      border-bottom: solid 1px $border-color;
      padding-bottom: 16px;
    }

    .disruption-title {
      color: $label-color;
      font-weight: 600;
      margin-top: 20px;
      flex: 0 1 50%;
    }

    .disruption-value {
      color: $body-color;
      font-weight: normal;
      margin-top: 20px;
      flex: 1;
      padding-left: 0;
    }

    .edit-disruption {
      cursor: pointer;
      color: var(--cap-primary-color);
    }

    .disruption-icon {
      color: var(--cap-primary-color);
    }
  }

  .form-invalid-feedback {
    display: none;
  }
}
