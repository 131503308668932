@import 'abstracts';

$login-panel: #fff;
$border-color: 1px solid rgba(112, 112, 112, 0.1);

app-login {
  .operator-login-screen {
    position: relative;
    width: 100%;
    height: 100vh;
    background-size: cover;
    @include flexCentering();
  }

  .operator-login-wrapper {
    position: absolute;
    width: 100%;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;

    @media (min-height: 700px) and (max-height: 900px) {
      top: 5%;
      bottom: 5%;
    }
  }

  .operator-content-wrapper {
    position: relative;
    height: 100%;
    overflow: hidden;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 5px 13px;
    margin-right: 75px;
    margin-left: 75px;

    @include media-breakpoint-down(md) {
      margin-right: 5px;
      margin-left: 5px;
    }

    @include media-breakpoint-up(lg) {
      min-width: 280px;

      .operator-content-right {
        padding: 70px 0 42px;
      }
    }

    .operator-content-left {
      @include flexCentering();
      padding: 15px;
      height: auto;
      text-align: center;
      background: $login-panel;
      flex-direction: column;
      border-bottom: $border-color;

      @include media-breakpoint-down(xl) {
        height: 185px;
      }

      @include media-breakpoint-up(lg) {
        border-right: $border-color;
        border-bottom: none;
        padding: 30px;
      }

      div.logo {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100px;
        max-width: 264px;

        @include media-breakpoint-down(xl) {
          height: 82px;
          width: 142px;
        }

        @include media-breakpoint-up(lg) {
          height: 160px;
        }
      }
    }
  }

  .operator-content-right {
    height: 100%;
    vertical-align: top;
    background: $login-panel;
    overflow-y: auto;

    @include media-breakpoint-up(lg) {
      padding: 40px;
    }

    .login-container {
      @include media-breakpoint-down(xl) {
        padding-top: 34px;
        padding-bottom: 30px;
      }
    }

    input {
      outline: none;
    }

    .cap-form-field-validator {
      display: none;
    }

    cap-validator {
      position: absolute;
      top: 12px;
      width: 72px;
      line-height: 11px;

      &.validator {
        margin-top: 0;
      }

      span.error-text {
        font-size: 1rem;
      }
    }

    .login-form {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include media-breakpoint-up(md) {
        min-height: 360px;
      }

      .title {
        margin-bottom: 28px;
      }

      .login-actions {
        @include flexCentering();
        margin-top: 31px;

        cap-button p-button {
          button {
            display: flex;
            justify-content: center;
            font-size: 1.25rem;
            font-weight: bold;
          }

          span.p-button-icon {
            margin-right: 10px;
          }

          span.p-button-label {
            flex: 0;
          }

          @include media-breakpoint-down(xl) {
            .cap-button {
              height: 36px;
              font-size: 0.88rem;
            }

            span.p-button-label {
              align-items: center;
              padding: 0;
            }
          }
        }
      }

      .credentials-login {
        padding-top: 57px;
      }
    }
  }
}
