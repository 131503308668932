@import 'abstracts';

.metis-sidebar.p-sidebar {
  padding: 0;

  &.p-sidebar-right {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 480px;
    }
  }

  .p-sidebar-header {
    display: flex;
    justify-content: space-between;
    background: var(--cap-primary-color);
    color: $cap-white;
    padding: 25px $sidebar-horizontal-padding;
    font-weight: bold;
    font-size: 1.2rem;

    .p-sidebar-close-icon {
      color: $cap-white;
      height: 1.2rem;
      width: 1.2rem;
    }
  }

  .p-sidebar-content {
    height: 100%;
    padding: 15px 15px 0;
    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }
}

.metis-sidebar formly-group .field-wrapper {
  @include media-breakpoint-up(sm) {
    max-width: 60%;
  }
}
