@import 'abstracts';

app-quality-inspection-dialog {
  cpb-list-renderer-filters {
    .table-filters {
      display: none !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  .th_edit {
    width: 5%;

    div {
      display: none;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $border-color;
    padding-top: 30px;
  }

  .confirm-button {
    background: $success;
    width: 166px;
  }

  .view-quality-button {
    background: $blue;
    width: 326px;
    margin-right: 21px;
  }

  .return-button {
    background: $blue;
  }
}
