@import 'abstracts';

@mixin custom-dialog {
  .popup-content {
    @include flexStart;
    padding: 29px 28.5px 31px 30.5px;
    overflow-y: auto;
    color: rgb(95, 96, 98);
    font-size: 1.5rem;
    font-weight: normal;
    min-height: 11.25rem;
  }

  .popup-actions {
    padding: 29px 0 31px;
    border-top: 1px solid $border-color;
    margin-left: 30.5px;
    margin-right: 28.5px;
  }
}

body .p-dialog {
  box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.2);
  width: 80%;

  .p-dialog-header {
    background: $confirmation-header;
    border-radius: 0;
    box-shadow: 0 2px 4px 0 $default-border-color;
    min-height: 5rem;
    padding: 20px 30px 27px;

    .p-dialog-title {
      font-size: 1.63rem;
      font-weight: bold;
      color: $white;
    }

    .p-dialog-header-close-icon {
      height: 1.63rem;
      width: 1.63rem;
      color: $white;
      text-align: center;
    }
  }

  .p-dialog-content {
    border: none;
    padding: 25px 28px 30px 31px;
    min-height: 20rem;

    cpb-form-renderer {
      .cbp-default-input-wrapper {
        label {
          color: $dark-gray;
          font-weight: bold;
          padding-bottom: 10px;
        }
      }

      div {
        &.p-inputgroup input.p-inputtext,
        &.p-inputgroup input.p-inputtext:hover,
        &.p-inputgroup input.p-inputtext:focus,
        &.p-inputgroup input.p-inputtext:enabled:focus:not(.p-state-error),
        &.p-inputgroup input.p-inputtext:enabled:hover:not(.p-state-error) {
          height: 60px;
        }

        &.p-inputgroup .p-inputgroup-addon {
          max-height: 60px;
        }
      }
    }
  }

  .p-dialog-footer {
    border: none;
    border-top: 1px solid $gray;

    cap-button {
      &:last-child {
        button {
          margin-right: 0;
        }
      }
    }

    p-footer {
      display: inline-flex;
      justify-content: center;
    }
  }
}

.responsive-dialog {
  @include responsiveDialog();
}

.p-dynamic-dialog {
  &.padding-0 {
    @include dialog-padding-0();
  }
}

.set-occupation {
  @include setDialogHeaderWithIcon('\f500');
}

.add-disruptions {
  @include setDialogHeaderWithIcon('\f04c');

  .p-dialog-header-icons {
    .p-dialog-header-icon {
      display: none;
    }
  }
}

.components {
  @include setDialogHeaderWithIcon('\e1d9');
}

.quality-deviation {
  @include setDialogHeaderWithIcon('\f044');
}

.quality-inspection {
  @include setDialogHeaderWithIcon('\f044');
}

.view-quality-inspections {
  @include setDialogHeaderWithIcon('\f03a');
}

.add-completed-order-operation-disruptions {
  @include setDialogHeaderWithIcon('\f071');
}

.dialog-layout--container {
  .dialog-layout--content {
    min-height: 10rem;
  }
}
