@import 'abstracts';

app-quality-deviation-dialog {
  .buttons {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $border-color;
    padding-top: 30px;
  }
}
