@import 'abstracts';

$start-color: #86bf00;
$pause-color: #005caa;
$stop-color: #e02000;
$on-hold-color: #fa6400;

app-process-order {
  .container {
    padding: 0;
  }

  .content {
    margin-left: 27px;
    margin-right: 20px;
  }

  .order-details {
    cap-button p-button {
      button {
        background: $blue;
        border: 1px solid $default-border-color;
        box-shadow: 0 2px 4px 0 $default-border-color;

        span.p-button-icon {
          margin-right: 0;
        }

        span.p-button-icon,
        span.p-button-label {
          font-size: 1rem;
          font-weight: bold;
        }
      }

      .p-button.cap-button {
        height: 46px;
        width: 230px;
        margin-bottom: 10px;
        border-radius: 30px;
      }
    }
  }

  .start-button,
  .pause-button,
  .stop-button,
  .on-hold-button,
  .resume-button {
    .cap-button {
      height: 60px;
      border-radius: 3px;
    }
  }

  .start-button {
    button {
      background: $start-color;
      border: 1px solid $start-color;
      box-shadow: 0 2px 4px 0 $start-color;
      width: 131px;
    }
  }

  .pause-button {
    button {
      background: $pause-color;
      border: 1px solid $pause-color;
      box-shadow: 0 2px 4px 0 $pause-color;
      margin-right: 17px;
    }
  }

  .stop-button {
    button {
      background: $stop-color;
      border: 1px solid $stop-color;
      box-shadow: 0 2px 4px 0 $stop-color;
      width: 131px;
    }
  }

  .on-hold-button {
    button {
      background: $on-hold-color;
      border: 1px solid $on-hold-color;
      box-shadow: 0 2px 4px 0 $on-hold-color;
      margin-right: 17px;
    }
  }

  .resume-button {
    button {
      background: $success;
      border: 1px solid $success;
      box-shadow: 0 2px 4px 0 $success;
      margin-right: 17px;
    }
  }

  .order-duration {
    cpb-text-input {
      cap-input {
        input {
          cursor: not-allowed;
          background: $gray-light;
          pointer-events: none;
          font-size: 1.13rem;
          color: $body-color !important; /* stylelint-disable-line declaration-no-important */
        }
      }
    }
  }

  .disruptions {
    font-size: 1.13rem;

    .disruption {
      border-bottom: solid 1px $border-color;
      padding-bottom: 16px;
    }

    .disruption-title {
      color: $label-color;
      font-weight: 600;
      margin-top: 20px;
      flex: 0 1 50%;
    }

    .disruption-value {
      color: $body-color;
      font-weight: normal;
      margin-top: 20px;
      flex: 1;
      padding-left: 0;
    }

    .disruption-icon {
      color: var(--cap-primary-color);
    }
  }

  formly-group .field-wrapper {
    display: flex;
  }
}

body .p-disabled,
body .p-component:disabled {
  box-shadow: none;
  background: $gray;
}
